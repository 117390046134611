/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { ImageResult } from '../data/fragments/ImageFragment'
import { ImgixImage } from './ImgixImage'
import style from './MessagesTile.module.sass'
import { MessagesTileMessage, MessagesTileMessageProps } from './MessagesTileMessage'

export type MessagesTileProps = {
	header: {
		title: React.ReactNode
		leftIcon?: ImageResult
		rightIcon?: ImageResult
	}
	messages: MessagesTileMessageProps[]
}

export const MessagesTile: React.FunctionComponent<MessagesTileProps> = ({
	header: { title, leftIcon, rightIcon },
	messages,
}) => {
	return (
		<div
			className={style.wrapper}
			onClick={(e) => {
				const target = e.target as HTMLElement
				console.log(target.tagName)
				if (target.tagName === 'A') {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					const link = target.href
					if (window.dataLayer) {
						window.dataLayer.push({
							event: 'clickTo_content',
							clickTo: 'box-fomo-picks',
							link: {
								type: 'link',
								text: target.innerHTML,
								url: link,
							},
						})
					}
				}
			}}>
			<div className={style.header}>
				<div className={style.headerIcon}>
					{leftIcon && (
						<ImgixImage
							src={leftIcon.url}
							width={leftIcon.width}
							height={leftIcon.height}
							alt={leftIcon.alt ?? ''}
						/>
					)}
				</div>
				{title && <h1 className={style.headerTitle}>{title}</h1>}
				<div className={style.headerIcon}>
					{rightIcon && (
						<ImgixImage
							src={rightIcon.url}
							width={rightIcon.width}
							height={rightIcon.height}
							alt={rightIcon.alt ?? ''}
						/>
					)}
				</div>
			</div>
			<div className={style.content}>
				{messages.map((item) => (
					<div key={item.id} className={style.item}>
						<MessagesTileMessage
							photo={item.photo}
							isReversed={item.isReversed}
							isSender={item.isSender}
							message={item.message}
						/>
					</div>
				))}
			</div>
		</div>
	)
}
