import clsx from 'clsx'
import React from 'react'
import type { ImageResult } from '../data/fragments/ImageFragment'
import { ImgixImage } from './ImgixImage'
import style from './MessagesTileMessage.module.sass'
import { BlockWrapperPath, BlockWrapperPathContext } from './RichText/BlockWrapper'

export interface MessagesTileMessageProps {
	id?: string
	photo?: ImageResult
	isReversed?: boolean
	isSender?: boolean
	message?: React.ReactChild
}

export const MessagesTileMessage: React.FunctionComponent<MessagesTileMessageProps> = ({
	photo,
	isReversed,
	isSender,
	message,
}) => {
	const path = React.useMemo<BlockWrapperPath>(() => [{ type: '' }], [])
	return (
		<BlockWrapperPathContext.Provider value={path}>
			<div
				className={clsx(
					style.wrapper,
					isReversed && style.view_reversed,
					isSender && style.is_sender
				)}>
				<div className={style.photo}>
					<ImgixImage
						src={photo?.url ?? '/user-profile.svg'}
						layout="fill"
						objectFit="cover"
						alt={photo?.alt ?? ''}
					/>
				</div>
				<div className={style.message}>{message ?? '…'}</div>
			</div>
		</BlockWrapperPathContext.Provider>
	)
}
