import Head from 'next/head'
import React from 'react'
import { Button } from '../components/Button'
import { MessagesTile } from '../components/MessagesTile'
import { useGtmPageData } from '../utils/useGtmPageData'
import { renderLayout } from './@layout'
import style from './error404.module.sass'

export default function Error404Page() {
	// TODO: create model + add data from contember
	const dataTitle = 'Maybe BFF 🤷‍♂️'
	const data = {
		header: {
			title: dataTitle,
		},
		messages: [
			{
				id: 'id-message-01',
				photo: {
					id: 'id-message-photo-01',
					url: 'https://picsum.photos/id/433/50/50',
					width: 50,
					height: 50,
				},
				message: (
					<>
						Kámo čouhej na tuhle <a href=".">stránku</a> 👀💯🔥
					</>
				),
				isSender: true,
			},
			{
				id: 'id-message-02',
				photo: {
					id: 'id-message-photo-02',
					url: 'https://picsum.photos/id/699/50/50',
					width: 50,
					height: 50,
				},
				message: 'Vždyť tu nic není!? 🤦‍♂️🤨',
				isReversed: true,
			},
		],
	}

	useGtmPageData({ page: { type: 'error' }, error: { type: '404', message: 'Stránka neexistuje' } })

	return (
		<>
			<Head>
				<title>404: Stránka neexistuje</title>
			</Head>
			<div className={style.wrapper}>
				<div className={style.infographic}>
					<MessagesTile header={data.header} messages={data.messages} />
				</div>
				<div className={style.content}>
					<h1 className={style.title}>404</h1>
					<p className={style.text}>
						Vypadá to, že tě &quot;{dataTitle}&quot; navedl na špatnou stránku!
					</p>
					<p className={style.text}>Příště už mu nevěř a zajdi na úvodní stránku!</p>
					<div className={style.button}>
						<Button type="link" link="/" view="dark">
							Zpět na úvodní stránku
						</Button>
					</div>
				</div>
			</div>
		</>
	)
}

export { renderLayout }
